import { Airport, PartnerInfo, Tax } from "../types/types";

const reservationActions = {
  updateReservation: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload,
  }),
  updateAirPort: (payload: Airport) => ({
    type: "UPDATE_RESERVATION",
    payload: { airport: payload },
  }),
  updatePartner: (payload: PartnerInfo) => ({
    type: "UPDATE_RESERVATION",
    payload: { partner: payload },
  }),
  updateDays: (payload: number) => ({
    type: "UPDATE_RESERVATION",
    payload: { days: payload },
  }),
  updateDailyRate: (payload: number) => ({
    type: "UPDATE_RESERVATION",
    payload: { dailyRate: payload },
  }),
  updateDeposit: (payload: number) => ({
    type: "UPDATE_RESERVATION",
    payload: { deposit: payload },
  }),
  updateStripeData: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { stripeData: payload },
  }),
  updateReservationResponse: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { reservationResponse: payload },
  }),
  updateTaxes: (payload: Tax[]) => ({
    type: "UPDATE_RESERVATION",
    payload: { taxes: payload },
  }),
  updateCheckin: (payload: string) => ({
    type: "UPDATE_RESERVATION",
    payload: { checkin: payload },
  }),
  updateCheckout: (payload: string) => ({
    type: "UPDATE_RESERVATION",
    payload: { checkout: payload },
  }),
  updatePackage: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { package: payload },
  }),
  updateCarDetails: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { carDetails: payload },
  }),
  updatePaymentDetails: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { payment: payload },
  }),
  updateStripeResponse: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { stripe_response: payload },
  }),
  updateCoupon: (payload) => ({
    type: "UPDATE_RESERVATION",
    payload: { coupon: payload },
  }),
};

export default reservationActions;
