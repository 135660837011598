import React, { useContext, useEffect } from "react";
import TopPageDetail from "../complete-component/TopPageDetail";
import ReservationDetails from "../complete-component/ReservationDetails";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createReservation, updateReservation } from "../service/UserApi";
import { GlobalContext, initialStateType } from "../globalContext";
import AdminLogo from "../assets/images/logo.svg";
import reservationActions from "../actions/ReservationActions";
import { STRIPE_SECRET_KEY } from "../../utils/StripeConfig";

const UpdateReservation = () => {
  const [loading, setIsLoading] = useState(true);
  // const [stripeData, setStripeData] = useState(null);
  // const [reservationResponse, setreservationResponse] = useState(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const { dispatch } = useContext(GlobalContext);
  const state: initialStateType = useContext(GlobalContext)?.state;
  const { reservation } = state;
  const { reservationResponse, stripeData } = reservation;
  const { days } = reservation;
  const params = useParams();
  const reservationId = params.id;
  const [shouldCreateReservation, setShouldCreateReservation] = useState(false);

  useEffect(() => {
    // const local_reserve = localStorage.getItem('update_reservation')
    // console.log({ reservation ,local_reserve});
    const paymentIntent = searchParams.get("payment_intent");
    if (reservation.checkin && paymentIntent) {
      getPaymentIntent(paymentIntent);
    }
    if (!paymentIntent) setIsLoading(false);
  }, [reservation.checkin]);

  useEffect(() => {
    const paymentIntent = searchParams.get("payment_intent");
    if (stripeData && paymentIntent) {
      if (shouldCreateReservation) {
        updateReservation(
          reservation,
          stripeData,
          paymentIntent,
          reservationId,
          (success, response) => {

            if (success) {
              // setreservationResponse(response);
              dispatch(reservationActions.updateReservationResponse(response));
              // alert(JSON.stringify(response)
              navigate('/reservation/' + response.data.id)
              // window.history.replaceState(null, "", "/reservation_Complete");
            }
            setIsLoading(false);
          }
        );
      }
    }
  }, [stripeData, shouldCreateReservation]);
  function extractPaymentDetails(jsonResponse) {
    // Assuming jsonResponse is a string, parse it into a JavaScript object
    const parsedResponse = jsonResponse;
    // payment_method_options
    if (parsedResponse.status == "succeeded") {
      const cardInfo =
        parsedResponse.charges.data[0]?.payment_method_details?.card;
      const paymentStatus = parsedResponse?.charges?.data[0].status;
      const receipt_url = parsedResponse.charges?.data[0]?.receipt_url;
      const amount = parsedResponse.amount;
      const currency = parsedResponse.currency;

      return {
        cardInformation: {
          brand: cardInfo?.brand || "",
          last4: cardInfo?.last4 || "",
          exp_month: cardInfo?.exp_month || "",
          exp_year: cardInfo?.exp_year || "",
          country: cardInfo?.country || "",
        },
        paymentStatus: paymentStatus,
        receipt_url: receipt_url,
        amount: amount,
        currency: currency,
      };
    } else {
      return null;
    }
  }

  // Example Usage (assuming you have the JSON response string in 'stripeResponse'):

  const getPaymentIntent = async (payment_intent) => {

    const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append(
      "Authorization",
      "Bearer " + STRIPE_SECRET_KEY
    );

    const urlencoded = new URLSearchParams();

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://api.stripe.com/v1/payment_intents/" + payment_intent,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const data = extractPaymentDetails(result);

        dispatch(reservationActions.updateStripeData(data));
        setShouldCreateReservation(true);

      })
      .catch((error) => console.error(error));
  };

  const [activeContent, setActiveContent] = useState("summary");





  if (loading)
    return (
      <div className="flex items-center justify-between min-h-fit complete-loading">
        <img className="max-w-[200px] w-full" src={AdminLogo} alt="" />
      </div>
    );
  return (
    <>
      <div></div>
      {/* <ReservationDetails details={reservationResponse} /> */}
    </>
  );
};

export default UpdateReservation;
