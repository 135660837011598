import React, { useState } from 'react';
import FilterCheckBoxes from '../home-components/FilterCheckBoxes';
// Adjust the import path as necessary

type FilterDropdownProps = {
    filters: string[];
    onClear: () => void;
    onApply: (selectedFilters: string[]) => void;
    selectedFilters: string[];
};

const FilterDropdown: React.FC<FilterDropdownProps> = ({ filters, onClear, selectedFilters, onApply }) => {
    const [filterDrop, setFilterDrop] = useState(false);
    // const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

    const handleCheckboxChange = (filter: string) => {
        const updatedFilters = selectedFilters?.includes(filter)
            ? selectedFilters.filter((item) => item !== filter)
            : [...selectedFilters, filter];
        onApply(updatedFilters);
    };
    const handleApplyFilters = () => {
        onApply(selectedFilters);
      };
    
    return (
        <div className="relative">
            <button
                className="filter-btn"
                onClick={() => {
                    setFilterDrop(true);
                }}
            >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5715 10.5941L17.4266 4.72014C17.7929 4.35183 18 3.84877 18 3.32376V1.60099C18 0.520023 17.1423 0 16.0844 0H1.91556C0.857655 0 0 0.520023 0 1.60099V3.3547C0 3.85177 0.18462 4.33087 0.517719 4.69419L5.89711 10.5632C5.9987 10.674 6.14034 10.7368 6.28979 10.7378L11.1915 10.7518C11.3332 10.7528 11.4699 10.6969 11.5715 10.5941Z" fill="#2EABE3" />
                    <path opacity="0.4" d="M6.05469 10.6855V17.2901C6.05469 17.5307 6.17581 17.7573 6.37411 17.887C6.48742 17.9619 6.61832 17.9998 6.74921 17.9998C6.84787 17.9998 6.94653 17.9789 7.03835 17.9369L11.0043 16.0884C11.2524 15.9736 11.4116 15.7211 11.4116 15.4426V10.6855H6.05469Z" fill="#2EABE3" />
                </svg>
            </button>
            {filterDrop && (
                <>
                    <div
                        className="fixed top-0 left-0 w-full h-screen"
                        onClick={() => {
                            setFilterDrop(false);
                        }}
                    ></div>
                    <div
                        className="filters-drop duration-150 rounded-[10px] flex flex-col gap-1 absolute left-0 bg-[#ffffff] min-w-max px-4 py-2 shadow-lg"
                        style={{ zIndex: '999999999' }}
                    >
                        {filters.map((filter, index) => (
                            <FilterCheckBoxes
                                key={index}
                                label={filter}
                                checked={selectedFilters?.includes(filter)}
                                onChange={() => handleCheckboxChange(filter)}
                            />
                        ))}
                        <div className="flex items-center gap-2 mt-4">
                            <button
                                style={{ height: 'auto', borderRadius: '5px', background: '#707070', color: 'white', padding: '3px' }}
                                type="button"
                                onClick={() => {
                                    onClear();
                                    setSelectedFilters([]);
                                  
                                    setFilterDrop(false);
                                }}
                            >
                                Clear
                            </button>
                            <button
                                style={{ height: 'auto', borderRadius: '5px', color: 'white', padding: '3px' }}
                                type="button"
                                onClick={() => {
                                    setFilterDrop(false);
                                    handleApplyFilters()
                                }}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </>
            )}
            <div
                className="filters-drop opacity-0 pointer-events-none group-hover:pointer-events-auto duration-150 group-hover:opacity-100 rounded-[10px] flex flex-col gap-1 absolute left-0 bg-[#ffffff] min-w-max px-4 py-2 shadow-lg"
                style={{ zIndex: '999999999' }}
            >
                <p className="text-black text-[16px] font-[500] cursor-pointer hover:text-[#2EABE3] duration-150">filter 1</p>
                <p className="text-black text-[16px] font-[500] cursor-pointer hover:text-[#2EABE3] duration-150">Verified parkings</p>
                <p className="text-black text-[16px] font-[500] cursor-pointer hover:text-[#2EABE3] duration-150">Nearest lots</p>
            </div>
        </div>
    );
};

export default FilterDropdown;