import React from 'react'
import { GlobalContext } from '../globalContext';
import { Reservation } from '../types/types';
type ParkingLotSummaryProps = {
    details: Reservation
}
const ParkingLotSummary = ({ details }: ParkingLotSummaryProps) => {


    return (
        <div className="w-full p-4 bg-[#EEEEEE]">
            <h3 className='text-[#2B2B2B] text-[18px] mb-2' >For Parking Lot Use Only</h3>
            <div className="w-full bg-[#F9F9F9] p-3">
                <h5 className='text-[#707070] text-[12px] uppercase'>Payment Summary</h5>
                <div className="grid grid-cols-2 mt-3">
                    <div className="flex flex-col">
                        <h4 className='text-[#2B2B2B] clash font-[600] text-[18px]'>${Number((Number(details?.total) - Number(details?.due_now))).toFixed(2)}</h4>
                        <p className=' text-[14px]'>Paid</p>
                    </div>
                    <div className="flex flex-col">
                        <h4 className='text-[#2B2B2B] clash font-[600] text-[18px]'>${Number(details?.due_now).toFixed(2)}</h4>
                        <p className=' text-[14px]'>Remaining Balance Due</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ParkingLotSummary
