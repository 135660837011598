import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import RatingStarsBar from "./RatingStarsBar";
import { PartnerInfo } from "../types/types";

type DetailsProps = {
  partner: PartnerInfo
};
const Details = ({ partner }: DetailsProps) => {


  const checkPartnerGalleryImages = (images: any[]) => {
    if (images != null) {
      if (images.length) {
        return images.map((item) => {
          return <SwiperSlide>
            <div className="detail-img">
              <img src={item?.path} alt="" />
            </div>
          </SwiperSlide>
        })

      } else {
        return <></>
      }
    } else {
      return <></>
    }
  }



  return (
    <>
      <div className="lg:px-10 px-5">
        <h4 className="text-[#2B2B2B] text-[16px] font-[400]">
          Details about the{" "}
          <span className="font-[700] text-[#2EABE3]">{partner?.name}</span>
        </h4>

        <div className="details-slider mt-5">
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={20}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation
            centeredSlides
            pagination={{ clickable: true }}
            slidesPerView={1}
          >
            {checkPartnerGalleryImages(partner?.images)}

          </Swiper>


        </div>
      </div>
    </>
  );
};

export default Details;
