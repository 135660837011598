import React from 'react'

const PaymentPRogressHead = ({ SelectParking, SelectParkingProgress, EnterInfo, EnterInfoProgress, Payment, PaymentProgress }) => {

    return (
        <div className="w-full bg-[#EEEEEE] py-7 px-0">
            <div className="container mx-auto">
                <div className="flex items-center justify-center gap-3 payment-progress-top">
                    <p className={`${SelectParking? 'selected' : ''} ${SelectParkingProgress? 'inprogress' : ''}`}>
                        Select Parking
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3332 4L5.99984 11.3333L2.6665 8" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </p>
                    <div></div>
                    <p className={`${EnterInfo? 'selected' : ''} ${EnterInfoProgress? 'inprogress' : ''}`}>
                        Enter Information
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3332 4L5.99984 11.3333L2.6665 8" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </p>
                    <div></div>
                    <p className={`${Payment? 'selected' : ''} ${PaymentProgress? 'inprogress' : ''}`}>
                        Payment
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.3332 4L5.99984 11.3333L2.6665 8" stroke="#707070" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PaymentPRogressHead
