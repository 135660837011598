import React from 'react'

const ParkingDealCard = ({icon, text}) => {
  return (
    <div className="deal-card">
      <img src={icon} alt='' />
      <p>{text}</p>
    </div>
  )
}

export default ParkingDealCard
