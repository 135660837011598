import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NavLink } from 'react-router-dom';
import { fetchPartners, updateReservation } from '../service/UserApi'
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import dayjs, { Dayjs } from "dayjs";
import DateTimePickerModal from '../home-components/CustomModal/DateTimePickerModal';
import { GlobalContext, initialStateType } from "../globalContext";
import reservationActions from "../actions/ReservationActions";
import { DATE_FORMAT, getDaysBetweenDates } from "../../utils/utils.ts";
import { PartnerInfo, Reservation } from '../types/types';
import moment from 'moment';
import StripePaymentForm from './StripePaymentForm';

type EditReservationModalProps = {
    setShowCancel: () => void
    details: Reservation
}
const EditReservationModal = ({ setShowCancel, details }: EditReservationModalProps) => {
    const [showDataPicker, setShowDatePicker] = useState(false);
    const [checkInData, setCheckInDate] = useState<Dayjs | null>(null);
    const [checkoutDate, setCheckoutData] = useState<Dayjs | null>(null);
    const [showReserveData, setShowReserveData] = useState(true);
    const { dispatch } = React.useContext(GlobalContext);
    const state: initialStateType = React.useContext(GlobalContext)?.state;
    const { reservation } = state;
    console.log({ reservation });

    const {
        airport,
        package: selectedPackage,
        dailyRate,
        days,
        checkin,
        checkout,
        taxes,
        deposit,

        coupon: discount,
        isResCompleted,
    } = reservation;
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const checkIn = urlParams.get('checkIn');
    const checkOut = urlParams.get('checkOut');
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [selectedPartnerData, setSelectedPartnerData] = useState<PartnerInfo | undefined>(undefined);
    const [partners, setPartners] = useState([]);
    const [partnersData, setPartnersData] = useState<PartnerInfo[]>([]);
    const [paidAmount, setPaidAmount] = useState(0);
    const [changeAmount, setChangeAmount] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [remainingAmount, setRemainingAMount] = useState(0);
    const FIXED_SERVICE_CHARGES = 5.95;


    useEffect(() => {
        if (details) {
            getPartners(details?.airport_id);
            setCheckInDate(dayjs(details.check_in))
            setCheckoutData(dayjs(details.check_out))
            setSelectedPartnerData(details.partner)
            rehydrateState(details);
            setQueryParams(details)
            const paidAmount = Number(details.total) - Number(details.due_now)
            setPaidAmount(paidAmount)
        }
    }, [details])

    const rehydrateState = (details: Reservation) => {
        // dispatch(reservationActions.updateCheckin(dayjs(details.check_in).format(DATE_FORMAT)))
        dispatch(reservationActions.updateCheckin(details.check_in))
        dispatch(reservationActions.updateCheckout(details.check_out))
        // dispatch(reservationActions.updateCheckout(dayjs(details.check_out).format(DATE_FORMAT)))
        dispatch(reservationActions.updateDays(getDaysBetweenDates(details?.check_in, details.check_out)))
        dispatch(reservationActions.updatePartner(details?.partner))
        dispatch(reservationActions.updateCoupon(details?.coupon))
        const modifiedtaxes = details.taxes.map((tax) => {
            return {
                "id": tax?.tax_id,
                "amount": tax?.tax_amount,
                "percentage": tax?.tax_percentage,

            }
        })
        dispatch(reservationActions.updateTaxes(modifiedtaxes))
        dispatch(reservationActions.updateDailyRate(details?.partner?.starting_price))
        dispatch(reservationActions.updateDeposit(details?.partner?.deposit))

    }
    const getPartners = async (reservationId: string) => {
        try {
            const response = await fetchPartners(reservationId);
            const partnerOptions = response.map((partner: PartnerInfo) => ({
                value: partner.id,
                label: partner.name,
            }));
            setPartners(partnerOptions);
            setPartnersData(response)


            if (details?.partner_id) {
                const selected = partnerOptions.find(it => it.value == details?.partner_id);
                setSelectedPartner(selected);


            }
        } catch (error) {
            console.error("Error fetching partners data:", error);
        }
    };




    const setQueryParams = (details: Reservation) => {
        const selectedData = {
            checkIn: `${moment(details?.check_in).format('YYYY-MM-DD')} ${moment(details?.check_in).format('hh:mm a')}`,
            checkOut: `${moment(details?.check_out).format('YYYY-MM-DD')} ${moment(details?.check_out).format('hh:mm a')}`,
        };

        const urlParams = new URLSearchParams(selectedData).toString();
        window.history.pushState(null, '', `?${urlParams}`);
    }
    useEffect(() => {


    }, []);


    const CheckInComponent = () => (
        <p className="text-[#2B2B2B] text-[16px] md:w-[200px] w-full check-date-text" style={{ color: '#2B2B2B !important' }} onClick={() => setShowDatePicker(true)}>
            {checkInData?.format(DATE_FORMAT) ?? 'Select Check-in'}
        </p>
    );

    const ChecoutComponent = () => (
        <p className="text-[#2B2B2B] text-[16px] md:w-[200px] w-full check-date-text" style={{ color: '#2B2B2B !important' }} onClick={() => setShowDatePicker(true)}>
            {checkoutDate?.format(DATE_FORMAT) ?? 'Select Check-out'}
        </p>
    );


    const buildUrlParams = () => {
        const params = new URLSearchParams();
        if (checkInData) params.set('checkIn', checkInData.format('YYYY-MM-DD hh:mm A'));
        if (checkoutDate) params.set('checkOut', checkoutDate.format('YYYY-MM-DD hh:mm A'));
        return params.toString();
    };

    const schema = yup
        .object({
            parking_lot: yup.string().required(),
            check_in: yup.string().required(),
            check_out: yup.string().required(),


        })
        .required();


    const {
        register,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });


    const onSubmit = async (data) => {

    };




    const calculateTotalCharges = (selectedPackage, dailyRate, days) => {
        let totalCharges = 0;
    
        const packageAmount = Number(dailyRate);
        if (days == 0) days = 1;
        totalCharges = packageAmount * days;
        console.log({ packageAmount, dailyRate, days, totalCharges });
    
    
        return totalCharges;
      };
      const totalCharges = calculateTotalCharges(selectedPackage, dailyRate, days);
    
      const calculateTax = (subtotal, tax) => {
        let taxAmount = 0;
    
        if (tax.percentage == "0") {
          taxAmount += Number(tax.amount);
        } else {
          let percentAmount = subtotal * (Number(tax.percentage) / 100);
          taxAmount += percentAmount;
        }
        return taxAmount;
      };
      const calculateTotalTax = (subtotal, taxes) => {
        subtotal = Number(subtotal);
        let totalTaxAmount = 0;
        for (let i = 0; i < taxes?.length; i++) {
          const tax = taxes[i];
          totalTaxAmount += calculateTax(subtotal, tax);
        }
        return totalTaxAmount;
      };
      const calculateDiscount = (total, discount) => {
        let discountAmount = 0;
        total = Number(total);
        if (discount) {
          if (discount.percentage == "0") {
            discountAmount += Number(discount.amount);
          } else {
            let percentAmount = total * (Number(discount.percentage) / 100);
            discountAmount += percentAmount;
          }
        }
        return discountAmount;
      };
      const calculateDeposit = (total, deposit) => {
        let depositAmount = 0;
        let percentAmount = total * (Number(deposit) / 100);
        depositAmount += percentAmount;
        return depositAmount;
      };
    
      const calculatePayments = (subtotal, taxes, discount, deposit) => {
        subtotal = Number(subtotal);
        const discountAmount = calculateDiscount(subtotal, discount);
        const tax = calculateTotalTax(subtotal - discountAmount, taxes);
    
        const total = subtotal + tax - discountAmount + FIXED_SERVICE_CHARGES;
        const response = {
          subtotal: subtotal,
          tax: tax,
          discountAmount: discountAmount,
          total: total,
          depositAmount:
            calculateDeposit(total - FIXED_SERVICE_CHARGES, deposit) +
            FIXED_SERVICE_CHARGES,
        };
        return response;
      };
      const { subtotal, tax, discountAmount, total, depositAmount } =
        calculatePayments(totalCharges, taxes, discount, deposit);

    const CalculateBalanceAmount = (reservation: Reservation, total: number) => {
        if (reservation) {
            return depositAmount - (Number(details.total) - Number(details.due_now))
        }
        return total
    }
    const balanceAmount = CalculateBalanceAmount(details, total)
    let formteBalance = balanceAmount.toFixed(2)
    if (balanceAmount >= 0) {
        formteBalance = "$" + Math.abs(balanceAmount).toFixed(2)
        
    } else {
        formteBalance = "- $" + Math.abs(balanceAmount).toFixed(2)
    }


    useEffect(() => {
        dispatch(
            reservationActions.updatePaymentDetails({
                subtotal,
                tax,
                discountAmount,
                total,
                depositAmount,
            })
        );
    }, [subtotal, tax, discountAmount, total, depositAmount]);

    useEffect(() => {



        const isMobile = window.innerWidth < 700;
        setShowReserveData(isMobile ? false : true);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };

    useEffect(() => {


        scrollToTop()
    }, [showDataPicker]);


    return (
        <div className="modal-holder">
            {showDataPicker && (

                <DateTimePickerModal

                    onClosePaymentModal={() => setShowDatePicker(false)}
                    onAllSelected={(date) => {

                        setCheckInDate(dayjs(date.checkIn, 'YYYY-MM-DD hh:mm A'));
                        setCheckoutData(dayjs(date.checkOut, 'YYYY-MM-DD hh:mm A'));
                        dispatch(reservationActions.updateCheckin(date.checkIn))
                        dispatch(reservationActions.updateCheckout(date.checkOut))
                        dispatch(reservationActions.updateDays(getDaysBetweenDates(date.checkIn, date.checkOut)))

                    }} />

            )}
            <div className="modal-outer" onClick={() => { setShowCancel(false) }}></div>
            <div className="modal-card-holder rounded-[10px] bg-[white] py-[20px] px-[20px] max-w-[600px] w-full mx-auto">
                <div className="flex justify-between items-center">
                    <h4 className='md:text-[24px] text-[18px] text-[#2B2B2B] font-[400] mb-4'>
                        Make changes on your reservation
                    </h4>
                    <button
                        onClick={() => { setShowCancel(false) }}>
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 2.5C8.0875 2.5 2.5 8.0875 2.5 15C2.5 21.9125 8.0875 27.5 15 27.5C21.9125 27.5 27.5 21.9125 27.5 15C27.5 8.0875 21.9125 2.5 15 2.5ZM20.375 20.375C20.2594 20.4909 20.122 20.5828 19.9708 20.6455C19.8196 20.7083 19.6575 20.7406 19.4937 20.7406C19.33 20.7406 19.1679 20.7083 19.0167 20.6455C18.8655 20.5828 18.7281 20.4909 18.6125 20.375L15 16.7625L11.3875 20.375C11.1538 20.6087 10.8368 20.74 10.5062 20.74C10.1757 20.74 9.85872 20.6087 9.625 20.375C9.39128 20.1413 9.25997 19.8243 9.25997 19.4937C9.25997 19.3301 9.29221 19.168 9.35484 19.0168C9.41747 18.8656 9.50927 18.7282 9.625 18.6125L13.2375 15L9.625 11.3875C9.39128 11.1538 9.25997 10.8368 9.25997 10.5062C9.25997 10.1757 9.39128 9.85872 9.625 9.625C9.85872 9.39128 10.1757 9.25997 10.5062 9.25997C10.8368 9.25997 11.1538 9.39128 11.3875 9.625L15 13.2375L18.6125 9.625C18.7282 9.50927 18.8656 9.41747 19.0168 9.35484C19.168 9.29221 19.3301 9.25997 19.4937 9.25997C19.6574 9.25997 19.8195 9.29221 19.9707 9.35484C20.1219 9.41747 20.2593 9.50927 20.375 9.625C20.4907 9.74073 20.5825 9.87812 20.6452 10.0293C20.7078 10.1805 20.74 10.3426 20.74 10.5062C20.74 10.6699 20.7078 10.832 20.6452 10.9832C20.5825 11.1344 20.4907 11.2718 20.375 11.3875L16.7625 15L20.375 18.6125C20.85 19.0875 20.85 19.8875 20.375 20.375Z" fill="#B8B6B6" />
                        </svg>
                    </button>
                </div>

                <div className="flex flex-col gap-4 add-partner-fields-holder" >
                    <div className="popup-field-box " >
                        <div className="flex items-center justify-between">
                            <label>Change parking lot</label>
                            <button
                                className={`${!showReserveData ? "rotate-180" : ""}`}
                                type="button"
                                onClick={() => {
                                    setShowReserveData(!showReserveData);
                                }}
                            >
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_839_8192)">
                                        <rect
                                            width="20"
                                            height="20"
                                            rx="10"
                                            transform="matrix(1 0 0 -1 0 20)"
                                            fill="#F2F2F2"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10.8828 6.61668C10.6484 6.38259 10.3307 6.25111 9.99943 6.25111C9.66818 6.25111 9.35047 6.38259 9.1161 6.61668L4.4011 11.33C4.16671 11.5645 4.03508 11.8825 4.03516 12.2141C4.03523 12.5456 4.16702 12.8635 4.40151 13.0979C4.63601 13.3323 4.95401 13.4639 5.28556 13.4639C5.61711 13.4638 5.93504 13.332 6.16943 13.0975L9.99943 9.26751L13.8294 13.0975C14.0651 13.3253 14.3808 13.4515 14.7085 13.4488C15.0363 13.4461 15.3498 13.3148 15.5817 13.0831C15.8136 12.8515 15.9452 12.538 15.9482 12.2103C15.9512 11.8825 15.8254 11.5667 15.5978 11.3308L10.8836 6.61584L10.8828 6.61668Z"
                                            fill="#2EABE3"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_839_8192">
                                            <rect
                                                width="20"
                                                height="20"
                                                rx="10"
                                                transform="matrix(1 0 0 -1 0 20)"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <Select
                        options={partners}
                        value={selectedPartner}
                        onChange={(selected) => {
                            setSelectedPartner(selected)
                            const partner = partnersData.find(it => it.id == selected?.value)
                            if (partner) setSelectedPartnerData(partner)
                            dispatch(reservationActions.updatePartner(partner))

                        }}
                        placeholder="Search airport..."
                        className="airport-dropdown"
                    />
                    {/* <input
                    {...register("parking_lot")}
                    type='text' placeholder='Enter Parking Lot name' /> */}
                </div>
                {showReserveData &&
                    <div className="grid md:grid-cols-2 grid-cols-2 gap-4 mt-5">
                        <div className="popup-field-box" >
                            <div className="checkin-box" style={{ border: 'none !important', }}>
                                <p>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.6667 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V3.99996C14 3.26358 13.403 2.66663 12.6667 2.66663Z" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M10.6641 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M5.33594 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2 6.66663H14" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                    Check in
                                </p>
                                <CheckInComponent />
                            </div>
                        </div>


                        <div className="checkin-box">
                            <p>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.6667 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V3.99996C14 3.26358 13.403 2.66663 12.6667 2.66663Z" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10.6641 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.33594 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2 6.66663H14" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                Check out
                            </p>
                            <ChecoutComponent />
                        </div>
                    </div>
                }



                {showReserveData &&
                    <div className="grid grid-cols-2 justify-between gap-1 items-center mt-6 pt-6 border-t border-[#C4C4C4]">
                        <p className='text-[#2B2B2B] text-[12px]'>You paid</p>
                        <p className='clash text-[#2B2B2B] text-[12px] font-[500] text-right'>${(Number(details.total) - Number(details.due_now)).toFixed(2)}</p>
                        <p className='text-[#2B2B2B] text-[12px]'>Previous Total</p>
                        <p className='clash text-[#2B2B2B] text-[12px] font-[500] text-right'>${Number(details.total).toFixed(2)}</p>
                        <p className='text-[#2B2B2B] text-[12px]'>Updated Deposit</p>
                        <p className='clash text-[#2B2B2B] text-[12px] font-[500] text-right'>${Number(depositAmount).toFixed(2)}</p>
                        <p className='text-[#2B2B2B] text-[12px]'>Updated Total</p>
                        <p className='clash text-[#2B2B2B] text-[12px] font-[500] text-right'>${Number(total).toFixed(2)}</p>
                        <p className='text-[#2B2B2B] text-[16px]'>Balance Deposit</p>
                        <p className='clash text-[#2B2B2B] text-[16px] font-[500] text-right'>{formteBalance}</p>
                    </div>
                }

                <p className='text-center  text-[12px] text-[#2EABE3] mt-4 mb-5'>If the amount is more than you paid, you need to pay remaining!
                    and if it’s less than you paid you will get refund in 24h!</p>


                {details?.id && Number(balanceAmount) > 0 && <StripePaymentForm reservationId={details?.id} paymentAmount={balanceAmount} />}


                <div className="flex items-center gap-4">
                    <button
                        onClick={() => { setShowCancel(false) }}
                        className='w-[120px] bg-[#EEEEEE] rounded-[10px] font-[600] text-center py-3 text-[#2B2B2B] w-full text-[16px] mt-4' type='submit'>
                        Discard
                    </button>
                    {/* <button onClick={() => {
                        // updateReservation(state.reservation, state.reservation.stripeData, "abc111222333", details.id, (response) => {
                        //     console.log({ response });

                        // })
                    }} className=' bg-[#2EABE3] rounded-[10px] text-center py-3 text-[white] w-full text-[16px] mt-4' type='submit'>
                        Make Changes
                    </button> */}
                </div>


                <p className='text-[#2B2B2B] text-[12px] text-center mt-5 pt-5  border-t border-[#C4C4C4]'>If you have any questions or concerns, contact us, email us at <NavLink to='#'>info@parkingmaniac.com</NavLink></p>
            </div>
        </div>



    )
}

export default EditReservationModal
