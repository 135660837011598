import React, { useEffect, useRef } from "react";
import { GlobalContext } from "../globalContext";
import { DATE_FORMAT, ONLY_DATE_FORMAT, getDatesBetween } from "../../utils/utils.ts";
import moment from "moment";
import { Reservation } from "../types/types";
type PaymentSummaryProps = {
  forPrint: boolean
  doPrint: () => void
  details: Reservation
}
const PaymentSummary = ({ forPrint, doPrint, details }: PaymentSummaryProps) => {




  const { state } = React.useContext(GlobalContext);
  const { reservation } = state;
  const {
    package: selectedPackage,
    dailyRate,
    days,
    checkin,
    checkout,


  } = reservation;

  const taxes = details?.taxes ?? []

  const calculateTax = (subtotal, tax) => {
    let taxAmount = 0;

    if (tax.tax_percentage == "0") {
      taxAmount += Number(tax.tax_amount);
    } else {
      let percentAmount = subtotal * (Number(tax.tax_percentage) / 100);
      taxAmount += percentAmount;
    }
    return taxAmount;
  };
  const calculateTotalTax = (subtotal, taxes) => {
    subtotal = Number(subtotal);
    let totalTaxAmount = 0;
    for (let i = 0; i < taxes?.length; i++) {
      const tax = taxes[i];
      totalTaxAmount += calculateTax(subtotal, tax);
    }
    return totalTaxAmount;
  };
  // const {depositAmount,total,discountAmount,subtotal} = payment
  return (
    <div className="block bg-[#EEEEEE] rounded-[4px] p-4">
      <p className="text-[#2EABE3] text-[12px] font-[400] text-center pb-4 mb-4 border-b border-[#C4C4C4]">
        we have sent the receipt to your given Email address!{" "}
      </p>

      <h4 className="text-[#707070] text-[12px] mb-4 uppercase">
        Payment Summary
      </h4>

      <div className="flex justify-between items-center">
        <h4 className="flex items-center gap-1 font-[600] text-[#707070] text-[12px]">
          Subtotal
          <span className="tool-box-holder">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                fill="#707070"
              />
            </svg>
            <div className="tool-tip-box">
              {getDatesBetween(moment(details?.check_in).format(DATE_FORMAT), moment(details?.check_out).format(DATE_FORMAT))?.map((date) => {
                return (
                  <p>
                    <strong>{moment(date).format(ONLY_DATE_FORMAT)}</strong>: $
                    {Number(Number(details?.sub_total) / days).toFixed(2)}
                  </p>
                );
              })}
              {/* <p>Our Daily Rates: ${dailyRate}</p>
                    <p>Package Price: ${subtotal - dailyRate}</p> */}
            </div>
          </span>
        </h4>
        <p className="text-[#707070] text-[12px] font-[500]">
          ${Number(details?.sub_total).toFixed(2)}
        </p>
      </div>
      {Number(details?.discount) > 0 && (
        <div className="flex justify-between items-center mt-2">
          <h4 className="flex items-center gap-1 font-[500] text-[#707070] text-[12px]">
            Discounts{" "}
            {/* <span className="tool-box-holder">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                  fill="#707070"
                />
              </svg>
              <div className="tool-tip-box">
                <p>You get {details?.coupon_percentage != '0' ? details?.coupon_percentage + '%' : "$" + details?.coupon_amount} discount on this <strong>{details?.coupon_code}</strong> Coupon code</p>

              </div>
            </span> */}
          </h4>
          <p className="text-[#707070] text-[12px] font-[500]">
            ${Number(details?.discount).toFixed(2)}
          </p>
        </div>
      )}
      <div className="flex justify-between items-center mt-2">
        <h4 className="flex items-center gap-1 font-[500] text-[#707070] text-[12px]">
          Lot Taxes & Fees
          <span className="tool-box-holder">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33203 11.333H8.66536V7.33301H7.33203V11.333ZM7.9987 5.99967C8.18759 5.99967 8.34603 5.93567 8.47403 5.80767C8.60203 5.67967 8.66581 5.52145 8.66536 5.33301C8.66536 5.14412 8.60136 4.9859 8.47336 4.85834C8.34536 4.73079 8.18714 4.66679 7.9987 4.66634C7.80981 4.66634 7.65159 4.73034 7.52403 4.85834C7.39648 4.98634 7.33248 5.14456 7.33203 5.33301C7.33203 5.5219 7.39603 5.68034 7.52403 5.80834C7.65203 5.93634 7.81025 6.00012 7.9987 5.99967ZM7.9987 14.6663C7.07648 14.6663 6.20981 14.4912 5.3987 14.141C4.58759 13.7908 3.88203 13.3159 3.28203 12.7163C2.68203 12.1163 2.20714 11.4108 1.85736 10.5997C1.50759 9.78856 1.33248 8.9219 1.33203 7.99967C1.33203 7.07745 1.50714 6.21079 1.85736 5.39967C2.20759 4.58856 2.68248 3.88301 3.28203 3.28301C3.88203 2.68301 4.58759 2.20812 5.3987 1.85834C6.20981 1.50856 7.07648 1.33345 7.9987 1.33301C8.92092 1.33301 9.78759 1.50812 10.5987 1.85834C11.4098 2.20856 12.1154 2.68345 12.7154 3.28301C13.3154 3.88301 13.7905 4.58856 14.1407 5.39967C14.4909 6.21079 14.6658 7.07745 14.6654 7.99967C14.6654 8.9219 14.4903 9.78856 14.14 10.5997C13.7898 11.4108 13.3149 12.1163 12.7154 12.7163C12.1154 13.3163 11.4098 13.7915 10.5987 14.1417C9.78759 14.4919 8.92092 14.6668 7.9987 14.6663Z"
                fill="#707070"
              />
            </svg>
            <div className="tool-tip-box">
              <p>
                <b>All Taxes</b>
              </p>
              {taxes.map((tax) => {
                const value =
                  tax?.amount === "0" ? tax?.percentage + "%" : tax?.amount;
                return (
                  <p key={tax?.tax_name}>
                    {tax.tax_name + ": $" + Number(calculateTax(details?.sub_total, tax)).toFixed(2)}
                    <br />
                  </p>
                );
              })}
            </div>
          </span>
        </h4>
        <p className="text-[#707070] text-[12px] font-[500]">
          ${Number(calculateTotalTax(details?.sub_total, taxes)).toFixed(2)}
        </p>
      </div>
      <div className="flex justify-between items-center mt-2">
        <h4 className="flex items-center gap-1 font-[500] text-[#707070] text-[12px]">
          Service charges
        </h4>
        <p className="text-[#707070] text-[12px] font-[500]">
          $5.95
        </p>
      </div>
      <div className="flex justify-between items-center mt-2">
        <h4 className="flex items-center gap-1 font-[500] text-[#707070] text-[12px]">
          Total
        </h4>
        <p className="text-[#707070] text-[12px] font-[500]">
          ${Number(details?.total).toFixed(2)}
        </p>
      </div>
      <div className="flex justify-between items-center mt-2">
        <h4 className="flex items-center gap-1 font-[500] text-[#2B2B2B] text-[16px]">
          You Paid
        </h4>
        <p className="text-[#2B2B2B] text-[16px] clash font-[500]">
          ${(Number(details?.total) - Number(details?.due_now)).toFixed(2)}
        </p>
      </div>
      <div className="flex justify-between items-center mt-2">
        <h4 className="flex items-center gap-1 font-[500] text-[#707070] text-[12px]">
          Due at Lot
        </h4>
        <p className="text-[#707070] text-[12px] font-[500]">
          $
          {(Number(details?.due_now)).toFixed(2)}
        </p>
      </div>

      {!forPrint && (
        <button
          onClick={() => {
            doPrint();
          }}
          className="text-[white] hover:bg-[#c77c47] bg-[#2EABE3] w-full mt-4 py-3 rounded-[4px] text-center"
          type="button"
        >
          Print Recipt
        </button>
      )}
    </div>
  );
};

export default PaymentSummary;
