import React from 'react'
import CollaborateBox from './CollaborateBox'

const CollaborateSection = () => {
    return (
        <section className='colab-sec'>
            <div className="container mx-auto">
                <div className="why-parking-title mb-10">
                    <h3>DO YOU WANT TO COLLABORATE WITH US?</h3>
                </div>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 gap-4">
                    <CollaborateBox collabTitle="Partner Lot" collabParah="Include your parking on our platform." />
                    <CollaborateBox collabTitle="Affiliates" collabParah="Add a redirected banner from your website." />
                    <CollaborateBox collabTitle="Corporate" collabParah="Book parking for your Employees." />
                    <CollaborateBox collabTitle="Advertising " collabParah="Advertise on our channels." />
                </div>
            </div>
        </section>
    )
}

export default CollaborateSection
