import React, { useContext, useEffect } from 'react'
import { useState } from 'react'

import Summary from '../search-page-component/Summary'
import Details from '../search-page-component/Details'
import AboutSpace from '../search-page-component/AboutSpace'
import ParkingReviewsHolder from '../search-page-component/ParkingReviewsHolder'
import OpeningHours from '../search-page-component/OpeningHours'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import PaymentDetailCard from '../Payment-component/PaymentDetailCard'
import { Airport, PartnerInfo } from '../types/types'
import moment from 'moment'
import AdminLogo from "../assets/images/logo.svg";

import { initialStateType, GlobalContext } from '../globalContext'
import { fetchPartnerDetails } from '../service/UserApi'
import SearchBox from './SearchBox'
import { DATE_FORMAT } from '../../utils/utils'

type ParkingTabContent = {
  fullScreen?: boolean
  callGoFull: () => {}
  OpenPaymentModal: (open: boolean, partner: PartnerInfo) => void;

  airport: Airport
  onShowMap: () => void

}


const ParkingTabContent = ({ airport, fullScreen, callGoFull, OpenPaymentModal, onShowMap }: ParkingTabContent) => {

  const [activeContent, setActiveContent] = useState('summary');
  const [makeFade, setMakeFade] = useState('')
  const [isloading, setIsloadin] = useState(true)
  const [partner, setPartner] = useState<PartnerInfo | undefined>(undefined)

  const [checkInTime, setCheckInTime] = useState<string | null>(null);
  const [checkOutTime, setCheckOutTime] = useState<string | null>(null);
  const [changeflag, setDateChangeFlag] = useState<any | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const state: initialStateType = useContext(GlobalContext)?.state;
  const { reservation, depositAmount } = state;
  const { checkin, checkout } = reservation;

  const params = useParams();
  const partnerId = params.id;
  const getPartner = async (partnerId: any) => {
    const partnerData = await fetchPartnerDetails(partnerId)
    setPartner(partnerData)
    setIsloadin(false)
    console.log(partnerData);

  }
  useEffect(() => {
    getPartner(partnerId)
  }, [partnerId])
  // useEffect(() => {
  //   const handleBackButton = (event) => {
  //     alert('listning')
  //     event.preventDefault();

  //     // Custom logic before navigating back
  //     if (window.confirm("Are you sure you want to go back?")) {
  //       // history.goBack();
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBackButton);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBackButton);
  //   };
  // }, []);

  const handleTabClick = (tabName) => {
    setActiveContent(tabName);

    // Scroll to the corresponding section
    const section = document.getElementById(tabName);
    section.scrollIntoView({ behavior: 'smooth' });
  };


  const location = useLocation(); // Get the current location object


  // useEffect(() => {
  //   // Initialize check-in and check-out times from URL parameters
  //   const searchParams = new URLSearchParams(location.search); // Get URL parameters
  //   const checkInParam = searchParams.get('checkIn'); // Get checkIn parameter from URL
  //   const checkOutParam = searchParams.get('checkOut'); // Get checkOut parameter from URL

  //   if (checkInParam && checkOutParam) {
  //     console.log({ checkin, checkout, checkInParam, checkOutParam });

  //     setCheckInTime(moment(checkInParam, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss'));
  //     setCheckOutTime(moment(checkOutParam, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm:ss'));
  //   }
  // }, [checkin, checkout]);
  const navigate  = useNavigate()
  const gotoSearch = () => {
    const params = new URLSearchParams();
    if (partner?.airport_id) params.set('airport_id',partner?.airport_id+'');
    if (checkin) params.set('checkIn',  moment(checkin, DATE_FORMAT).format('YYYY-MM-DD hh:mm A'));
    if (checkout) params.set('checkOut',  moment(checkout, DATE_FORMAT).format('YYYY-MM-DD hh:mm A'));
    navigate('/search?'+params.toString())
  };

  useEffect(() => {

    // Check if check-in or check-out times are out of business hours
    if (checkin && checkout) {
      const checkInDateTime = moment(checkin, DATE_FORMAT)
      const checkOutDateTime = moment(checkout, DATE_FORMAT)

      const businessHours = partner?.business_info;
      if (businessHours) {
        const checkInDay = checkInDateTime.format('dddd').toLowerCase();
        const checkOutDay = checkOutDateTime.format('dddd').toLowerCase();
        const cnTime = moment(checkInTime).format('YYYY-MM-DD')
        const coTime = moment(checkOutTime).format('YYYY-MM-DD')

        const checkInStart = moment(`${checkInDateTime.format('YYYY-MM-DD')} ${businessHours[`${checkInDay}_start_time`]}`);
        const checkInEnd = moment(`${checkInDateTime.format('YYYY-MM-DD')} ${businessHours[`${checkInDay}_end_time`]}`);
        const checkOutStart = moment(`${checkOutDateTime.format('YYYY-MM-DD')} ${businessHours[`${checkOutDay}_start_time`]}`);
        const checkOutEnd = moment(`${checkOutDateTime.format('YYYY-MM-DD')} ${businessHours[`${checkOutDay}_end_time`]}`);

        const checkInTimeValid = checkInDateTime.isBetween(checkInStart, checkInEnd, null, '[)');
        const checkOutTimeValid = checkOutDateTime.isBetween(checkOutStart, checkOutEnd, null, '[)');

        if (!checkInTimeValid || !checkOutTimeValid) {
          let errorMessages = [];

          if (!checkInTimeValid) {
            errorMessages.push(`Check-in time should be between ${checkInStart.format('hh:mm A')} and ${checkInEnd.format('hh:mm A')} on ${checkInDay.charAt(0).toUpperCase() + checkInDay.slice(1)}.`);
          }

          if (!checkOutTimeValid) {
            errorMessages.push(`Check-out time should be between ${checkOutStart.format('hh:mm A')} and ${checkOutEnd.format('hh:mm A')} on ${checkOutDay.charAt(0).toUpperCase() + checkOutDay.slice(1)}.`);
          }

          setErrorMessage(errorMessages.join(' '));
        } else {
          setErrorMessage('');
        }
      }

    }
  }, [checkin, checkout, partner?.business_info]);


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,

    });
  };
  scrollToTop()


  const calculatePercetage = (_totalAmount: string, _currentAmount: string) => {
    const totalAmount = Number(_totalAmount);
    const currentAmount = Number(_currentAmount);
    if (totalAmount == 0 || currentAmount == 0) return <div></div>
    const percentage = (currentAmount / totalAmount) * 100
    const percentageString = percentage.toFixed(0);
    return <div className="flex justify-start items-center">
      <h5 className=''><span className=''></span>-{percentageString}</h5>
      <p >%off</p>
    </div>

  }

  React.useEffect(() => {
    // setMakeFade('')
    // setMakeFade('fader')
    // setTimeout(() => {
    //   setMakeFade('')
    // }, 300);
  }, [partner]);
  if (isloading) return <div className="flex items-center justify-between min-h-fit complete-loading w-[90%] mx-auto"> <img src={AdminLogo} alt="" /> </div>
  return (

    <div className={`tab-content-card-holder    bg-[#FAFAFA] w-full`}>
      <div className="lg:block hidden">
        <SearchBox page={'inner'} disable_airport
          onBackPress={()=>{
            gotoSearch()
          }}
          onDateChanged={() => {
            setDateChangeFlag(moment().milliseconds())

          }} />
        <div className="flex gap-10 max-w-[1200px] mx-auto">
          <div className="w-full max-w-[60%]">
            <Summary partner={partner} errorMessage={errorMessage} OpenPaymentModal={OpenPaymentModal}
              onShowMap={() => {
                onShowMap && onShowMap()
              }}
              fullScreen={fullScreen} callGoFull={callGoFull} />
            <div className="px-10">
              <div className="park-divider"></div>
            </div>
            <Details partner={partner} />
            <div className="px-10">
              <div className="park-divider"></div>
            </div>
            <AboutSpace partner={partner} />
            <div className="px-10">
              <div className="park-divider"></div>
            </div>
            <ParkingReviewsHolder partner={partner} />
            <div className="px-10">
              <OpeningHours partner={partner} />
            </div>
          </div>
          <div className='w-full max-w-[40%] h-full bg-white pt-4 pb-0 rounded-b-[20px] shadow-lg sticky top-0 overflow-hidden right-sticky'>
            <div className="flex items-center justify-center ">
              {partner?.discounted_price && <>
                <div className="flex flex-col items-center justify-center border-r pr-2 mr-2 border-[#d4d4d4]">

                  <p className=' block text-[18px] text-[#2B2B2B] font-[500] '><span className='line-through'>${partner?.discounted_price ?? 0}</span> </p>

                  {/* <div className='border mt-[-2px] text-[12px] text-[white] border-solid w-[60px] rounded-full h-[20px] bg-[#2eabe3] flex items-center justify-center clash'> {calculatePercetage(partner?.discounted_price ?? "0", partner?.starting_price ?? "0")}</div> */}


                </div>
              </>
              }

              <h4 className='clash text-[23px]  flex flex-col font-[500] text-[#2B2B2B]'>${Number(partner?.starting_price)} <span className='clash text-[#2B2B2B] mt-[-10px] text-[15px] text-[400]'> Per day</span> </h4>

            </div>


            <NavLink to='/payment' className={`flex px-10 mt-4 justify-center text-center items-center whitespace-nowrap gap-3 bg-[#E32E67]  hover:bg-[#000000] transition  text-white py-4 ${errorMessage ? 'opacity-5 pointer-events-none' : ''} `} type="button">Reserve Now <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3312_6037)"><path d="M1.16406 7H12.8307M12.8307 7L7.58073 1.75M12.8307 7L7.58073 12.25" stroke="white" stroke-width="2"></path></g><defs><clipPath id="clip0_3312_6037"><rect width="14" height="14" fill="white"></rect></clipPath></defs></svg></NavLink>
          </div>
          {/* <div className="w-full max-w-[40%] h-full">
            <div className="sticky top-0 ">
              <PaymentDetailCard partner={partner} airport={airport} />
            </div>
          </div> */}
        </div>
      </div>

      <div className="lg:hidden block py-4">
        {/* <div className="flex items-center justify-center menu-tab-holder">
          <NavLink to="#summary" className={`w-full max-w-[100px] py-3 px-1  sm:text-[16px] text-[12px] font-[400] ${activeContent === 'summary' ? 'border-b border-[#2EABE3] text-[#2B2B2B]' : 'text-[#707070]'}`} onClick={() => handleTabClick('summary')} >Summary</NavLink>
          <NavLink to="#details" className={`w-full max-w-[100px] py-3 px-1  sm:text-[16px] text-[12px] font-[400] ${activeContent === 'details' ? 'border-b border-[#2EABE3] text-[#2B2B2B]' : 'text-[#707070]'}`} onClick={() => handleTabClick('details')}>Details</NavLink>
          <NavLink to="#opening" className={`w-full max-w-[100px] py-3 px-1  sm:text-[16px] text-[12px] font-[400] ${activeContent === 'opening' ? 'border-b border-[#2EABE3] text-[#2B2B2B]' : 'text-[#707070]'}`} onClick={() => handleTabClick('opening')}>Opening Hours</NavLink>
       
          <NavLink to="#reviews" className={`w-full max-w-[100px] py-3 px-1  sm:text-[16px] text-[12px] font-[400] ${activeContent === 'review' ? 'border-b border-[#2EABE3] text-[#2B2B2B]' : 'text-[#707070]'}`} onClick={() => handleTabClick('review')}>Review</NavLink>
        </div> */}

<SearchBox page={'inner'} disable_airport
          onBackPress={()=>{
            gotoSearch()
          }}
          onDateChanged={() => {
            setDateChangeFlag(moment().milliseconds())

          }} />


        <div id="summary">
          <Summary onShowMap={() => {
            onShowMap && onShowMap()
          }} OpenPaymentModal={OpenPaymentModal} partner={partner} fullScreen={fullScreen} callGoFull={callGoFull} />

          <div className="lg:px-10 px-5">
            <div className="park-divider"></div>
          </div>
        </div>

        <div id="details">
          <Details partner={partner} />
          <div className="lg:px-10 px-5">
            <div className="park-divider"></div>
          </div>
        </div>


        <div id="opening">
          <OpeningHours partner={partner} />
          <div className="lg:px-10 px-5">
            <div className="park-divider"></div>
          </div>
        </div>

        <AboutSpace partner={partner} />
        <div className="lg:px-10 px-5">
          <div className="park-divider"></div>
        </div>
        <div id="reviews">
          <ParkingReviewsHolder partner={partner} />
        </div>


        <div className='w-full max-w-[40%] h-full bg-white pt-4 pb-0 rounded-b-[20px] shadow-lg sticky top-0 overflow-hidden right-sticky'>
          <div className="flex items-center justify-center ">
            {partner?.discounted_price && <>
              <div className="flex flex-col items-center justify-center border-r pr-2 mr-2 border-[#d4d4d4]">

                <p className=' block text-[18px] text-[#2B2B2B] font-[500]  text-[#2EABE3]'><span className='line-through'>${partner?.discounted_price ?? 0}</span> </p>

                <div className='border mt-[-2px] text-[12px] text-[white] border-solid w-[60px] rounded-full h-[20px] bg-[#2eabe3] flex items-center justify-center clash'> {calculatePercetage(partner?.discounted_price ?? "0", partner?.starting_price ?? "0")}</div>


              </div>
            </>
            }

            <h4 className='clash text-[23px]  flex flex-col font-[500] text-[#2B2B2B]'>${Number(partner?.starting_price)} <span className='clash text-[#2B2B2B] mt-[-10px] text-[15px] text-[400]'> Per day</span> </h4>

          </div>


          <NavLink to='/payment' className={`flex px-10 mt-4 justify-center text-center items-center whitespace-nowrap gap-3 bg-[#E32E67]  hover:bg-[#000000] transition  text-white py-4 ${errorMessage ? 'opacity-5 pointer-events-none' : ''} `} >Reserve Now <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3312_6037)"><path d="M1.16406 7H12.8307M12.8307 7L7.58073 1.75M12.8307 7L7.58073 12.25" stroke="white" stroke-width="2"></path></g><defs><clipPath id="clip0_3312_6037"><rect width="14" height="14" fill="white"></rect></clipPath></defs></svg></NavLink>

        </div>


      </div>

    </div>



  )
}

export default ParkingTabContent
