import React, { useContext, useEffect } from 'react'
import RatingStarsBar from './RatingStarsBar'
import ReserveParkingBtn from './ReserveParkingBtn'
import { useState } from 'react'
import { PartnerInfo } from '../types/types'
import PaymentModal from '../components/PaymentModal'
import { useCallback } from 'react'
import { getActiveBagdes } from '../../utils/badgeHelper'
import { GlobalContext, initialStateType } from '../globalContext'
import moment from 'moment'
import { DATE_FORMAT } from '../../utils/utils.ts'
import { useLocation } from 'react-router'
import AirportIcon from '../assets/images/airport-icon.png'
import { NavLink } from 'react-router-dom'

type SymmaryProps = {
    partner: PartnerInfo
    fullScreen?: boolean
    OpenPaymentModal: (open: boolean, partner: PartnerInfo) => void;
    callGoFull: () => void
    onShowMap: () => void
    errorMessage?:string
}
const Summary = ({ partner, errorMessage,fullScreen, callGoFull, OpenPaymentModal, onShowMap }: SymmaryProps) => {

    const [fullBar, setFullBar] = useState(false)

    const [paymentModal, setPaymentModal] = useState(false)
    const [checkInTime, setCheckInTime] = useState<string | null>(null);
    const [checkOutTime, setCheckOutTime] = useState<string | null>(null);

    const state: initialStateType = useContext(GlobalContext)?.state;
    const { reservation, depositAmount } = state;
    const { checkin, checkout } = reservation;

    const onClosePaymentModal = useCallback(() => {
        setPaymentModal(false);
    }, [paymentModal]);


    const calculatePercetage = (_totalAmount: string, _currentAmount: string) => {
        const totalAmount = Number(_totalAmount);
        const currentAmount = Number(_currentAmount);
        if (totalAmount == 0 || currentAmount == 0) return <div></div>
        const percentage = (currentAmount / totalAmount) * 100
        const percentageString = percentage.toFixed(0);
        return <div className="flex justify-start items-center">
            <h5 className=''><span className=''></span>-{percentageString}</h5>
            <p >%off</p>
        </div>

    }


   



    return (

        <>

            {/* {paymentModal &&
                <PaymentModal onClosePaymentModal={onClosePaymentModal} />
            } */}

            <div className="lg:px-10 px-5">

                <div className="flex justify-between">
                    <div className='badge-holder' style={{ display: 'flex', flexDirection: 'row' }}>
                        {getActiveBagdes(partner).map((item, index) => (
                            <div className='card-badge' key={index} style={{ margin: '0 10px' }}>
                                <span className='text-[#2EABE3] text-[12px] font-[400] py-[6px] px-[10px] bg-[#E6F2F8] rounded-br-[10px] rounded-bl-[10px] block w-fit'>  {item}</span>
                            </div>
                        ))}
                    </div>
                 
                  
                </div>

                <div className="flex justify-between mt-5">
                    <div className="block">
                        <h2 className='text-[#2B2B2B] text-[30px] font-[700] '>{partner?.name}</h2>
                        {/* <p className='flex items-center gap-2 text-[#2B2B2B] text-[13px] font-[400] mb-2'>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.75 7.5C15.75 12.75 9 17.25 9 17.25C9 17.25 2.25 12.75 2.25 7.5C2.25 5.70979 2.96116 3.9929 4.22703 2.72703C5.4929 1.46116 7.20979 0.75 9 0.75C10.7902 0.75 12.5071 1.46116 13.773 2.72703C15.0388 3.9929 15.75 5.70979 15.75 7.5Z" fill="#7D8892" />
                                <path d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z" fill="white" />
                            </svg>
                            {partner?.address}</p> */}
                    </div>
                    {/* <div className="block">
                        <button className={` unset cursor-pointer`}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="4" fill="#EEEEEE" />
                                <path d="M8 12V16C8 16.2652 8.10536 16.5196 8.29289 16.7071C8.48043 16.8946 8.73478 17 9 17H15C15.2652 17 15.5196 16.8946 15.7071 16.7071C15.8946 16.5196 16 16.2652 16 16V12" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M14 9L12 7L10 9" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12 7V14" stroke="#2B2B2B" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>


                    </div> */}
                </div>
                <div className="flex items-center gap-2 mt-2 mb-2 text-[12px] text-[#2B2B2B] ">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.9933 2.28109C12.3375 2.62526 12.3375 3.17943 11.9933 3.51776L9.72417 5.78693L10.9608 11.1478L10.1383 11.9761L7.87501 7.64193L5.60001 9.91693L5.81001 11.3578L5.18584 11.9761L4.15917 10.1211L2.29834 9.08859L2.91667 8.45859L4.37501 8.67443L6.63251 6.41693L2.29834 4.13609L3.12667 3.31359L8.48751 4.55026L10.7567 2.28109C11.0833 1.94276 11.6667 1.94276 11.9933 2.28109Z" fill="#2EABE3" />
                    </svg>
                    {partner?.mile} miles away from - {partner?.airport.code}
                </div>
                <RatingStarsBar rating={partner?.average_rating} />

                <div className="mt-3"></div>
                <div className="flex items-center ">
                    {partner?.discounted_price && <>
                        <div className="flex flex-col items-center justify-center border-r pr-2 mr-2 border-[#d4d4d4]">
                            <p className=' block text-[18px] text-[#2B2B2B] font-[500] '><span className='line-through'>${partner?.discounted_price ?? 0}</span></p>
                            {/* <div className='border mt-[-2px] text-[12px] text-[white] border-solid w-[60px] rounded-full h-[20px] bg-[#2eabe3] flex items-center justify-center clash'>  {calculatePercetage(partner?.discounted_price ?? "0", partner?.starting_price ?? "0")}</div> */}
                        </div>
                    </>
                    }
                    <h4 className='clash text-[23px]  flex flex-col font-[500] text-[#2EABE3]'>${Number(partner?.starting_price)} <span className='clash text-[#2B2B2B] mt-[-10px] text-[15px] text-[400]'> Per day</span> </h4>

                </div>
                <div className="mb-3"></div>


                {/* <button className={`${fullScreen ? ' ' : ''} unset  md:block hidden`} type="button"
                    onClick={() => {
                        setFullBar(!fullBar)
                        callGoFull(!fullBar)
                    }}
                >
                    <div className=" items-center gap-2 my-5 md:flex hidden">

                        <svg className={`${fullBar ? 'rotate-180' : ''}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="24" height="24" rx="2" fill="#EEEEEE" />
                            <path d="M10 5V11H16V13H10V19L3 12L10 5ZM18 19V5H20V19H18Z" fill="#2B2B2B" />
                        </svg>

                        {`${fullBar ? 'View Parking lots' : 'Hide Parking Lots'}`}
                    </div>
                </button><br /> */}
                {errorMessage && <p className='text-[16px] text-[#c33838] font-[600]'>{errorMessage}</p>}
                {errorMessage == '' && <>
                    {partner.active == "1" && <NavLink to='/payment'
                        className='flex px-10 w-fit rounded-[10px] items-center whitespace-nowrap gap-3 bg-[#E32E67] text-white py-2'
                    // onClick={() => {
                    //     OpenPaymentModal(true, partner)
                    // }}

                    >Reserve Now <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_3312_6037)">
                                <path d="M1.16406 7H12.8307M12.8307 7L7.58073 1.75M12.8307 7L7.58073 12.25" stroke="white" stroke-width="2" />
                            </g>

                            <defs>
                                <clipPath id="clip0_3312_6037">
                                    <rect width="14" height="14" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </NavLink>}

                    <div className='pt-[10px] clash text-[#2EABE3] mt-2 gap-2'>✔ Free Cancellation</div>
                </>}


                {/* <button
                    className='lg:hidden px-10 flex rounded-[10px] items-center whitespace-nowrap gap-3 bg-[#2EABE3] text-white py-2'
                    onClick={() => {
                        setPaymentModal(true)
                    }}
                    type='button'
                >Reserve Now <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3312_6037)">
                            <path d="M1.16406 7H12.8307M12.8307 7L7.58073 1.75M12.8307 7L7.58073 12.25" stroke="white" stroke-width="2" />
                        </g>

                        <defs>
                            <clipPath id="clip0_3312_6037">
                                <rect width="14" height="14" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </button> */}

            </div>
        </>
    )
}

export default Summary
