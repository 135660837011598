import React from "react";
import BannerSec from "../home-components/BannerSec";
import ParkingDealSection from "../home-components/ParkingDealSection";
import SatisfiedUsersSection from "../home-components/SatisfiedUsersSection";
import FingerSection from "../home-components/FingerSection";
import DownloadApp from "../home-components/DownloadApp";
import WhyParking from "../home-components/WhyParking";
import NewsLetter from "../home-components/NewsLetter";
import CollaborateSection from "../home-components/CollaborateSection";

import { Fade } from 'react-reveal';


const Home = () => {
  return (
    <div className="">
      <Fade delay={500}>
        <BannerSec />
      </Fade>
      <Fade delay={500}>
        <ParkingDealSection />
      </Fade>
      <Fade delay={500}>
        <SatisfiedUsersSection />
      </Fade>
    
        <WhyParking />
  
      <Fade delay={500}>
        <NewsLetter />
      </Fade>
      <Fade delay={500}>
        <CollaborateSection />
      </Fade>
    </div>)
    ;
};

export default Home;
