import React from 'react'
import RatingStarsBar from './RatingStarsBar'
import Avatar from '../assets/images/avatar.svg'

const ParkingReviewsBox = ({ RevName, RevDesc, RevDate, RatingStars }) => {
    return (
        <div className="park-rev-box">
            <div className="text-right flex justify-end">
                <RatingStarsBar rating={RatingStars} showNumbers={true} />
            </div>
            <div className="flex items-center gap-2">
                <img className='rounded-[50%] w-[55px] h-[55px] ' src={Avatar} alt='' />
                <div className="flex flex-col">
                    <h5 className='text-[#2B2B2B] text-[12px] font-[700]'>{RevName}</h5>
                    <p className='text-[#2B2B2B] text-[15px] font-[400] mb-2'>{RevDesc}</p>
                </div>
            </div>
            <div className="text-right">
                    <p className='text-[#707070] text-[10px] font-[500]'>{RevDate}</p>
                </div>
        </div>
    )
}

export default ParkingReviewsBox
