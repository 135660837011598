import React from 'react';

const FilterCheckBoxes = ({ label, checked, onChange }) => {
  return (
    <p className="text-black text-[16px] font-[500] cursor-pointer hover:text-[#2EABE3] duration-150 flex items-center gap-2">
      <input type="checkbox" checked={checked} onChange={onChange} />
      {label}
    </p>
  );
};

export default FilterCheckBoxes;