import React from 'react'
import PaymentPRogressHead from '../checkOut-Signup-Components/PaymentPRogressHead'
import EnterDetailForm from '../checkOut-Signup-Components/EnterDetailForm'
import EnterInfo from '../checkOut-Signup-Components/EnterInfo'

const CheckOutSignup = () => {
    return (
        <div className="fader">
            <PaymentPRogressHead
                SelectParking={true}
                EnterInfoProgress={true} />

            <div className="w-full bg-[#FAFAFA] lg:py-20 py-10">

                <div className="container mx-auto">
                    <div className="flex gap-10 lg:flex-row flex-col-reverse">
                        <div className="w-full lg:max-w-[70%] max-w-[100%]">
                            <EnterDetailForm />
                        </div>
                        <div className="w-full lg:max-w-[30%] max-w-[100%]">
                        <EnterInfo/>
                        </div>
                    </div>
                </div>

            </div>


            </div>
    )
}

export default CheckOutSignup
