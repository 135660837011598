import React, { useReducer } from "react";

export const AuthContext = React.createContext();

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  role: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("role", action.payload.role);
      return {
        ...state,
        isAuthenticated: true,
        token:  action.payload.token,
        role: action.payload.role,
      };
    case "USERDATA":
      localStorage.setItem("UserData", JSON.stringify(action.payload.user));
      return {
        ...state,
        user: action.payload.user,
      };
    case "LOGOUT":
      localStorage.removeItem("role");
      localStorage.removeItem("token");
      document.title = "Parking Maniac";
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

export const tokenExpireError = (dispatch, errorMessage) => {
  /**
   * either this or we pass the role as a parameter
   */
  const role = localStorage.getItem("role");
  if (errorMessage === "TOKEN_EXPIRED") {
    dispatch({
      type: "LOGOUT",
    });

    location.href = "/" + role + "/login";
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  React.useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const userJSON = localStorage.getItem("UserData");
    const userData = JSON.parse(userJSON)
    if (token) {
      (async function () {
        try {
          dispatch({
            type: "LOGIN",
            payload: {
              token,
              role: role,
            },
          });
          dispatch({
            type: "USERDATA",
            payload: {
              user: userData,
            },
          });
        } catch (error) {
          // if (role) {
          //   dispatch({
          //     type: "LOGOUT",
          //   });
          //   window.location.href = "/" + role + "/login";
          // } else {
          //   dispatch({
          //     type: "LOGOUT",
          //   });
          //   window.location.href = "/";
          // }
        }
      })();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
