import React, { useState } from 'react';

const SignupEmailBox = () => {
  const [formData, setFormData] = useState({
    email: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    const Sheet_Url = "https://script.google.com/macros/s/AKfycbxVzVkSHqcBu49KSx8ZJWbInMS0MQynGft0Lo-kepIHYsUSOe7-lMKKVGLDieA_pQb1/exec"; // Replace with your web app URL

    try {
      await fetch(Sheet_Url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      setFormData({
        
        email: '',
   
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="signup-email-box">
        <input
        type='email'
        id="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        required
        placeholder='Email'
      />
      <button type='submit'>Subscribe</button>
    </form>
  );
};

export default SignupEmailBox;
