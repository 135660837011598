import React from 'react'
import mapIcon from '../assets/images/map-pin-icon.svg'
import ParkingDealCard from './ParkingDealCard'
import ParkingTabSection from "../home-components/ParkingTabSection";
import dealIcon1 from '../assets/images/star-cion.svg'
import dealIcon2 from '../assets/images/ear-icon.svg'
import dealIcon3 from '../assets/images/car-icon.svg'
import dealIcon4 from '../assets/images/ticket-icon.svg'
import dealIcon5 from '../assets/images/price-tag-icon.svg'
import dealIcon6 from '../assets/images/flat-icon.svg'






const ParkingDealSection = () => {
  return (
    <section className='parking-deal-sec'>
      <div className="container mx-auto">
        <h2>Airport Parking Deals Near You <img src={mapIcon} alt='' /> </h2>


        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 md:gap-7 gap-2 mt-10" >
          <ParkingDealCard icon={dealIcon1} text="Discounted long term rates $6.54++" />
          <ParkingDealCard icon={dealIcon2} text="24/7 supports" />
          <ParkingDealCard icon={dealIcon3} text="More then 500 partnered parking lots" />
          <ParkingDealCard icon={dealIcon4} text="Free cancellations Refunds" />
          <ParkingDealCard icon={dealIcon5} text="Best quality prices" />
          <ParkingDealCard icon={dealIcon6} text="Parking at airports all over the U.S" />
        </div>
        {/* <ParkingTabSection/> */}
      </div>
    </section>
  )
}

export default ParkingDealSection
