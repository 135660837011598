import React, { useRef } from "react";
import DoneReserv from "../assets/images/done-reservation.svg";
import { Reservation } from "../types/types";


type TopPageDetailProps = {
  forPrint: boolean
  doPrint: () => void
  details: Reservation
}
const TopPageDetail = ({ details, doPrint, forPrint }: TopPageDetailProps) => {
  console.log(details);

  if (!details) return <div></div>;

  return (
    <div className="flex justify-between md:flex-row flex-col  items-center bg-[#EEEEEE] p-4 gap-2 ">
      <div className="flex md:flex-row flex-col  gap-2">
        <img className="w-[33px] mx-auto" src={DoneReserv} />
        <div className="flex flex-col text-center-mob">
          <h4 className="clash flex items-center gap-2 text-[#2EABE3] text-[24px]">
            Your Parking Reservation  {details?.status == 'cancel' ? <span className="bg-[red] text-[white] rounded-full text-[14px] py-1 px-3 uppercase">Cancelled</span> : ''}
            {/* {details?.status == 'paid' ? <span className="bg-[#298829] text-[white] rounded-full text-[14px] py-1 px-3 uppercase">{details?.status}</span> : ''} */}
          </h4>
          <p className="text-[12px] text-[#2B2B2B]">
            Thanks for your recent airport parking reservation. Below is
            important information about your parking reservation.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-3 md:max-w-fit w-full">
        <div className="flex justify-between w-full md:flex-wrap row-gap-2 gap-2">
          <div className="block">
            <p className="text-[#707070] text-[12px]">Reservation by:</p>
            <h3 className="text-[#2B2B2B]">{details?.user_name}</h3>
          </div>
          <span className="text-[#2B2B2B] text-[14px] rounded-[10px] bg-[#DBDBDB] inline-block h-fit py-1 px-3">
            Reservation ID #PM{details?.id}
          </span>
        </div>
        {!forPrint && (
          <button
            onClick={() => {
              doPrint();
            }}
            type="button"
            className="flex justify-center items-center gap-2 bg-[#2EABE3] text-center py-3 text-[12px] text-white lg:w-[340px]"
          >
            Download Receipt{" "}
            <svg
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.20833 5.5V8.70833H2.79167V5.5H1.875V8.70833C1.875 9.2125 2.2875 9.625 2.79167 9.625H9.20833C9.7125 9.625 10.125 9.2125 10.125 8.70833V5.5H9.20833ZM6.45833 5.80708L7.64542 4.62458L8.29167 5.27083L6 7.5625L3.70833 5.27083L4.35458 4.62458L5.54167 5.80708V1.375H6.45833V5.80708Z"
                fill="white"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default TopPageDetail;
