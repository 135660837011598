import moment from "moment";
import { BASE_URL, api } from "./api";
import { DATE_FORMAT } from "../../utils/utils.ts";
import { Reservation } from "../types/types";

export const getAllTaxes = async () => {
  const resp = await api.get("/taxes?airport_id=1");

  return resp.data;
};

export const getAllDeposit = async () => {
  const resp = await api.get("/settings/deposit_fee?airport_id=1");

  return resp.data;
};

export const verifyCoupon = async (airportId: string, code: string) => {
  const resp = await api.post(`/coupon/verify?airport_id=${airportId}&code=${code}`);

  return resp.data;
};

export const getAirports = async () => {
  const resp = await api.get("/airports");

  return resp.data;
};
export const fetchPartners = async (airport_id) => {
  let params = ''
  if (airport_id) {
    params += 'airport_id=' + airport_id
  }
  const resp = await api.get("/partners?" + params);

  return resp.data;
};

export const getOperatingTime = async () => {
  const resp = await api.get("/business-information");

  return resp.data;
};
export const fetchPartnerDetails = async (id: string) => {
  const resp = await api.get(`/partners/${id}`);

  return resp.data;
};

export const getPackages = async () => {
  //   const myHeaders = new Headers();
  // myHeaders.append("Accept", "application/json");
  // myHeaders.append("Cookie", "XSRF-TOKEN=eyJpdiI6IjNoOGRkaWdkWlJrWVpvclNtdmhSWVE9PSIsInZhbHVlIjoiK0I4ZFd4SkJ2SUhiYlQ5cFgvK3NvbENFWk1nZ1BMV2Raa1ZiSEFnbU5nS2w2S0tGbFpQd1FIYWMwejkwUGRXdmwwTWo0NmxYdU1nT2E0RnR2OEdmUGNuNzh3cExFcmg3Z0xDMlhkSlZ6Tlpaek1oajJsWnZwVXVUVnpWQm4xcGsiLCJtYWMiOiI3NjI4MjRlZjk1OWVhNjllY2UxMTY5MjA4Y2Y5NWNmZTVlNmQxNWRkMTZiMDY4MmY0NDM4Y2VmYzZlNGM3NjNmIiwidGFnIjoiIn0%3D; park_n_depart_session=eyJpdiI6IkRMNEIzVk9Qc2pGMkdxOHZ0ZVduc1E9PSIsInZhbHVlIjoieC9pVVpsQ1J3UWtUbE5zOUxPUTVmTm4zNVNKSTlCYUFhY2hoaXh0d1JjVVpQN2xZcC94UHc5aFpLeEpMTE1TTW5mY2x4VkR1dkxlUWN1clhwR3NiS0ZZelFWa1Y5ZGE0d1BObjlITDU3T2tOYnp5WmdNQlBwa3JvVnFGWWJhRVMiLCJtYWMiOiJkMmU2ZTgxODFiNTgxNjg4M2YyY2VkMjhlNjZiODRkODcwNzI2N2FkZDlkMjkwYmQ0MjhmM2IxNjRkMjM1MTcwIiwidGFnIjoiIn0%3D");

  const resp = await api.get("/packages?airport_id=1");
  return resp.data;
};

export const AddRequest = (params, onResponse) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
  };

  fetch(
    `${BASE_URL}/reservation/0/cancel?name=${params.name}&email=${params.email}&subject=${params.subject}&reason=${params.reason}`,
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => onResponse(true, result))
    .catch((error) => console.error(error));
};

export const createReservation = (data, transaction, paymentId, onResponse) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  const {
    carDetails,
    airport,
    package: selectedPackage,
    checkin,
    checkout,
    partner,
    payment,
    coupon
  } = data;
  const formdata = new FormData();
  formdata.append("user_first_name", carDetails?.name);
  formdata.append("user_last_name", carDetails?.lname);
  formdata.append("email", carDetails?.email);
  formdata.append("phone_number", carDetails?.phone);
  formdata.append("airport_id", airport?.id + "");
  formdata.append("partner_id", partner.id);
  { coupon?.id && formdata.append("coupon_id", coupon?.id); }
  formdata.append("package_id", selectedPackage?.id);
  formdata.append("car_make", 'TEMP_MAKE');
  formdata.append("car_model", 'TEMP_MODEL');
  formdata.append("car_color", 'TEMP_COLOR');
  formdata.append(
    "check_in",
    moment(checkin, DATE_FORMAT).format("YYYY-MM-DD HH:mm:ss")
  );
  formdata.append(
    "check_out",
    moment(checkout, DATE_FORMAT).format("YYYY-MM-DD HH:mm:ss")
  );
  formdata.append("sub_total", Number(payment?.subtotal).toFixed(2) + "");
  formdata.append("discount", Number(payment?.discountAmount).toFixed(2) + "");
  formdata.append("total", payment?.total + "");
  formdata.append(
    "due_now",
    (payment?.total - payment?.depositAmount).toFixed(2) + ""
  );
  formdata.append("payment_method_name", "Stripe");
  formdata.append("payment_transaction_id", paymentId);
  formdata.append("payment_card_name", transaction.cardInformation.brand);
  formdata.append("payment_card_number", transaction.cardInformation?.last4);
  formdata.append(
    "payment_card_expiry",
    transaction.cardInformation?.exp_month +
    " " +
    transaction.cardInformation?.exp_year
  );
  formdata.append("payment_card_cvc", "000");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  fetch(
    BASE_URL + "/reservation",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => onResponse(true, result))
    .catch((error) => console.error(error));
};

export const updateReservation = (data, transaction, paymentId, reservationId, onResponse) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  const {
    carDetails,
    airport,
    package: selectedPackage,
    checkin,
    checkout,
    partner,
    payment,
    coupon
  } = data;
  const formdata = new FormData();
  formdata.append("partner_id", partner.id);


  // formdata.append("package_id", selectedPackage?.id);

  formdata.append(
    "check_in",
    moment(checkin, 'YYYY-MM-DD hh:mm a').format("YYYY-MM-DD HH:mm:ss")
  );
  formdata.append(
    "check_out",
    moment(checkout, 'YYYY-MM-DD hh:mm a').format("YYYY-MM-DD HH:mm:ss")
  );
  formdata.append("sub_total", Number(payment?.subtotal).toFixed(2) + "");
  formdata.append("discount", Number(payment?.discountAmount).toFixed(2) + "");
  formdata.append("total", payment?.total + "");
  formdata.append(
    "due_now",
    (payment?.total - payment?.depositAmount).toFixed(2) + ""
  );
  formdata.append("payment_method_name", "Stripe");
  formdata.append("payment_transaction_id", paymentId);
  formdata.append("payment_card_name", transaction.cardInformation.brand);
  formdata.append("payment_card_number", transaction.cardInformation?.last4);
  formdata.append(
    "payment_card_expiry",
    transaction.cardInformation?.exp_month +
    " " +
    transaction.cardInformation?.exp_year
  );
  formdata.append("payment_card_cvc", "000");
  console.log({ checkin, checkout });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  fetch(
    BASE_URL + "/reservation/" + reservationId,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => onResponse(true, result))
    .catch((error) => console.error(error));
};
export const fetchReservation = async (reservationId: string) => {


  const resp = await api.get(`/reservation/${reservationId}`);

  return resp.data;
};

export const cancleReservation = async (reservation: Reservation, reason: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");

  const formdata = new FormData();
  formdata.append("name", reservation.user_name);
  formdata.append("email", reservation.email);
  formdata.append("subject", "Booking canceled -" + reservation?.partner?.name);
  formdata.append("reservation_id", reservation.id + '');
  formdata.append("reason", reason ?? "DEFAULT DELETE MESSAGE");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
  };

  const rawResponse = await fetch(BASE_URL + "/reservation/" + reservation.id + "/cancel", requestOptions)
  return await rawResponse.json()
  // .then((response) => response.json())
  // .then((result) => console.log(result))
  // .catch((error) => console.error(error));
}