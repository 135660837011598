import React from 'react'
import { NavLink } from 'react-router-dom'
import { GlobalContext } from '../globalContext';
import { Reservation } from '../types/types';
type DirectionsProps = {
    details: Reservation
}
const Directions = ({ details }: DirectionsProps) => {

    const { state } = React.useContext(GlobalContext);
    const { businessInfo } = state
    return (
        <div className="block bg-[#EEEEEE] rounded-[4px] p-4">
            <h4 className="flex items-center gap-2 text-[#2B2B2B] text-[16px]">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_839_9698)">
                        <path d="M7.50112 8.33348C7.28011 8.33348 7.06815 8.42128 6.91187 8.57756C6.75559 8.73384 6.66779 8.9458 6.66779 9.16681V12.5001H8.33446V10.0001H10.8345V12.0835L13.7511 9.16681L10.8345 6.25015V8.33348H7.50112ZM10.5903 1.16098L18.8403 9.41098C18.9965 9.56725 19.0843 9.77918 19.0843 10.0001C19.0843 10.2211 18.9965 10.433 18.8403 10.5893L10.5903 18.8393C10.434 18.9955 10.2221 19.0833 10.0011 19.0833C9.78015 19.0833 9.56823 18.9955 9.41196 18.8393L1.16196 10.5893C1.00573 10.433 0.917969 10.2211 0.917969 10.0001C0.917969 9.77918 1.00573 9.56725 1.16196 9.41098L9.41196 1.16098C9.56823 1.00476 9.78015 0.916992 10.0011 0.916992C10.2221 0.916992 10.434 1.00476 10.5903 1.16098Z" fill="#2EABE3" />
                    </g>
                    <defs>
                        <clipPath id="clip0_839_9698">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
                Directions
            </h4>

            <p className='text-[#2B2B2B] text-[12px] mt-3 mb-2'>For a map and directions to the parking lot, <NavLink className='underline' to={details?.partner?.map_link}>click here</NavLink></p>
            <p className='text-[#2B2B2B] text-[12px] border-b border-[#C4C4C4] pb-3  mb-3'>For help with directions, please contact the parking lot at the phone number: <NavLink to={`tel:${details?.partner?.phone_number}`} className='text-[#2EABE3] font-[600] text-[14]'>    {details?.partner?.phone_number} </NavLink></p>
            <p className='max-w-[270px] mx-auto text-center text-[#2B2B2B] text-[12px]'>
                If you have any questions or concerns, contact us, email us at <NavLink className='underline' to={`mailto:${details?.partner?.email}`}>{details?.partner?.email}</NavLink>
            </p>
        </div>
    )
}

export default Directions
