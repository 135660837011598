import React from 'react'
import Rating from '../assets/images/rating-img.svg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation,Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import RatingStarsBar from '../search-page-component/RatingStarsBar';

import { Fade } from 'react-reveal';





const WhyParking = () => {
    return (
        <section className='why-parking'>
            <div className="container mx-auto">
                <Fade delay={500}>
                <div className="why-parking-title">
                    <h3>Why Parking Maniac?</h3>
                    <p>Thousands of users have chosen us as their trusted app for everyday parking and also when travelling.</p>
                </div>
                </Fade>

                <Fade delay={500}>
                <div className="why-testi-holder">
                    <Swiper
                    modules={[Autoplay, Navigation, Pagination]}
                        spaceBetween={20}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                          }}   
                        navigation
                   
                        slidesPerView={1}>
                        <SwiperSlide>

                            <div className="why-testi-box">
                                <div className="flex items-center justify-between">
                                    <h3>Jonas Birk </h3>
                                   
                                    <RatingStarsBar rating={5} />
                                </div>
                                <p>"I do a lot of driving and a lot of traveling, and I use the Parking Maniac to find myself parking, fast and easy.
                                "</p>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>

                            <div className="why-testi-box">
                                <div className="flex items-center justify-between">
                                    <h3>Stephen</h3>
                                    <RatingStarsBar rating={5} />
                                </div>
                                <p>“A Great online service and everything was as described”</p>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide>

                            <div className="why-testi-box">
                                <div className="flex items-center justify-between">
                                    <h3>Andy Anago</h3>
                                    <RatingStarsBar rating={5} />
                                </div>
                                <p>"So today I want to tell you about my newest obsession, I saved money on city parking with Parking Maniac.
                                "</p>
                            </div>

                        </SwiperSlide>


                    </Swiper>

                </div>
                </Fade>
            </div>
        </section>
    )
}

export default WhyParking
