import React, { useContext, useEffect } from "react";
import TopPageDetail from "../complete-component/TopPageDetail";
import ReservationDetails from "../complete-component/ReservationDetails";
import { NavLink, useParams } from "react-router-dom";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { createReservation, fetchReservation } from "../service/UserApi";
import { GlobalContext, initialStateType } from "../globalContext";
import AdminLogo from "../assets/images/logo.svg";
import reservationActions from "../actions/ReservationActions";
import { Reservation as ReservationModel } from "../types/types";

const Reservation = () => {
  const [loading, setIsLoading] = useState(true);
  const [reservation, setReservation] = useState<ReservationModel | undefined>(undefined);

  const params = useParams();
  const reservationId = params.id;

  useEffect(() => {
    localStorage.removeItem('update_reservation')
    if (reservationId) {
      fetchReservation(reservationId).then((response: ReservationModel) => {
        console.log({ response });

        setReservation(response)
      }).catch((e: any) => {
        console.log(e, e.message);
      }).finally(() => {
        setIsLoading(false)
      })
    }

  }, [reservationId]);


  if (loading)
    return (
      <div className="flex items-center justify-between min-h-fit complete-loading">
        <img className="max-w-[200px] w-full" src={AdminLogo} alt="" />
      </div>
    );
  return (
    <>
      {reservation != undefined && <ReservationDetails details={reservation} />}
      {reservation == undefined && <div className='payment-placeholder'><h1>Reservation not Found</h1> </div>}

    </>
  );
};

export default Reservation;
